.dock-nav-wrap {
  justify-content: center;
}

.dock-ink-bar {
  @apply bg-trtp-primary;
}

.dock-tab:hover {
  @apply text-trtp-primary;
}

  .dock-tab-active,
  .dock-tab-active:hover {
    @apply text-trtp-primary;
  }

.dock-tab-disabled {
  cursor: default;
  color: #ccc;
}
.dock-tab-disabled:hover {
  color: #ccc;
}
:root.dark .dock-top .dock-bar {
  border-bottom: 1px solid black !important;
  background-color: var(--trtp-background) !important;
}
:root.dark .dock-panel {
  @apply border-gray-800 bg-black;
}

:root.dark .dock-fbox > .dock-panel {
  box-shadow: 0 0 4px #333 !important;
}

:root.dark .dock-tab {
  @apply text-white;
  background-color: #141414 !important;
  border-bottom: 1px solid #141414 !important;
}

.dock-tab {
  border-bottom: 1px solid white !important;
}
