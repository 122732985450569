* {
  font-family: 'Messina Sans';
  scrollbar-width: thin;

    /* For iOS devices, disable text selection */
  -webkit-user-select: none;
}

body {
  overflow: hidden;
}

canvas {
  -webkit-user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

div {
  @apply select-none
}

a:hover {
  color: var(--trtp-primary)
}

  :root.dark body {
    background-color: black; 
    color-scheme: dark;
  }

  /* Track */
  :root.dark ::-webkit-scrollbar-track {
    background: #000;
  }

  /* Handle */
  :root.dark ::-webkit-scrollbar-thumb {
    /* background: #c6a66d; */
    background: rgb(50, 50, 50);
  cursor: pointer;
  }

  /* Handle on hover */
  :root.dark ::-webkit-scrollbar-thumb:hover {
    background: var(--trtp-primary);
  cursor: pointer;
  }

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

  :root.light body {
    background-color: var(--trtp-background);
  }

  /* Track */
  :root.light ::-webkit-scrollbar-track {
    background: rgb(240, 240, 240);
  }

  /* Handle */
  :root.light ::-webkit-scrollbar-thumb {
    background: rgb(210, 210, 210);
    /* background: #1b9e4b; */
  }

  /* Handle on hover */
  :root.light ::-webkit-scrollbar-thumb:hover {
    background: var(--trtp-primary);
  }
