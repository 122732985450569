@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://app.tesseractpro.io/fonts/messina/stylesheet.css"); */

@layer components {
  .text-selected {
    @apply text-trtp-primary transition-colors cursor-default;
  }

  .text-selectable {
    @apply hover:text-trtp-primary-hover  transition-colors cursor-pointer;
  }

}

* {
  overscroll-behavior: none;
}

body {
  overflow: hidden;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
