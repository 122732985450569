:root.dark .chrome-picker  {
  background-color: #110000 !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) {
  display: none !important;
}

:root.dark .sketch-picker > div:nth-child(4) {
 border-top: 1px solid black !important;
}

:root.dark .sketch-picker > div:nth-child(3) input {
 box-shadow: none !important;
}

:root.dark .sketch-picker > div:nth-child(3) label {
 color: white !important;
}
