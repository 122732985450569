/* .dock-nav-animated { */
/*   transition: unset; */
/* } */

/* .dock-tab { */
/*   transition: unset; */
/* } */
.dock-content-animated {
  transition: unset;
}

/* .dock-bottom > .dock-nav .dock-ink-bar-animated, */
/* .dock-top > .dock-nav .dock-ink-bar-animated, */
/* .dock-bottom > div > .dock-nav .dock-ink-bar-animated, */
/* .dock-top > div > .dock-nav .dock-ink-bar-animated { */
/*   transition: unset; */
/* } */

.dock-tabpane:not(.dock-tabpane-active) {
  animation-name: unset;
  animation-duration: unset;
}
.dock-nav-list {
  display: flex;
  position: relative;
  transition: unset;
}
.dock-dropdown-menu-item {
  transition: unset;
}

.dock-layout > .dock-drop-indicator {
  transition: unset;
}

.dock-panel-max-btn,
.dock-panel-min-btn {
  transition: unset;
}
.dock-layout > :not(.dock-fbox) .dock-panel.dock-style-headless .dock-bar {
  transition: unset;
}
